import { CampaignLanguage } from "@models/CampaignLanguage";
import { RegistrationType } from "@builder-models/RegistrationType";

export class AdventCalendarGameData {
    id?: string;
    calendarType: string;
    campaignId: string;
    startDate: string;
    endDate: string;
    registrationType?: RegistrationType;
    tiles: TilePrize[];
    outcomes: Array<{ outcomes: Outcome[] }>;
}

export class TilePrize {
    id?: string;
    dayNumber: number;
    prizes: TilePrizeByLanguage[];
}

export class TilePrizeByLanguage {
    ctaList?: any;
    id?: string;
    text: string;
    imageUrl: string;
    description: string;
    languageId: string;
    redirection?: RedirectionSettings;
}

export class RedirectionSettings {
    url: string;
    forwardQueryParameters: boolean;
}

export class Outcome {
    id?: string;
    title: string;
    text: string;
    image: string;
    languageId: string;
    ctaList?: any[];
    socialMediaShare?: any;
}

export class AdventCalendarGameDataFactory {
    static getInitial(languages: CampaignLanguage[], campaignId: string, calendarType: string): AdventCalendarGameData {
        const currentYear = new Date().getFullYear();

        let days; let startDate; let endDate;
        // TODO turn to switch statement
        if (calendarType === "advent") {
            days = 24;
            startDate = `${currentYear}-12-1`;
            endDate = `${currentYear}-12-24`;
        } else if (calendarType === "weekly") {
            days = 7;
            startDate = `${currentYear}-1-21`;
            endDate = `${currentYear}-1-28`;
        } else if (calendarType === "monthly"){
            days = 31;
            startDate = `${currentYear}-1-01`;
            endDate = `${currentYear}-1-31`;
        } else {
            throw new Error("calendarType not recognized");
        }

        return {
            calendarType,
            campaignId,
            startDate,
            endDate,
            tiles: getTiles(languages, days),
            outcomes: Array.from({ length: days }, (_, i) => ({
                outcomes: languages.map((language) => ({
                    title: `Day ${i + 1}`,
                    text: `Outcome ${i + 1} text`,
                    image: "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/434-200x200.jpg",
                    languageId: language.id,
                })),
            })),
        };
    }
}

export const getTiles = (languages, tileNumber = 24) => {
    const tiles = [];
    for (let i = 1; i <= tileNumber; i++) {
        tiles.push({
            dayNumber: i,
            prizes: languages.map((language) => ({
                text: "Prize text " + i.toString(),
                imageUrl: "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/434-200x200.jpg",
                description: "Prize description " + i.toString(),
                languageId: language.id,
                redirection: {
                    url: "",
                    forwardQueryParameters: false,
                },
            })),
        });
    }
    return tiles;
};

export const getDefaultTile = (languageIds: {[key: string]: string}) => ({
    dayNumber: 1,
    prizes: Object.keys(languageIds).map((language) => ({
        text: "Prize text 1",
        imageUrl: "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/434-200x200.jpg",
        description: "Prize description 1",
        languageId: languageIds[language],
        redirection: {
            url: "",
            forwardQueryParameters: false,
        },
    })),
});

export const getDefaultOutcome = (languageIds: {[key: string]: string}) => (
    {
        outcomes: Object.keys(languageIds).map((language) => ({
            title: "Day 1",
            text: "Outcome 1 text",
            image: "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/434-200x200.jpg",
            languageId: languageIds[language],
        })),
    }
);
