export enum FeatureFlagConfig {
    WIN_DISTRIBUTION = "b20_10608__4d77650bd84b9fa760805d9bb595a503",
    UNIFIED_ANALYTICS = "e20_29__c71c04f74b5d389e0b347960b9474b79",
    INTERNAL_ANALYTICS = "b20_10669__ad62b91a4eaf9b7b0db62fedd3edbd93",
    ACCESS_CODES_ANALYTICS = "b20_11396__aa08db367a787ab0c6b62462a3d0be91",
    LEADS_VISIBILITY = "b20_12146__ace2d278ba3f862fdb539816d0f7f9aa",
    ASTW_SWITCH = "b20_10608__dc6a6b38741e637fe895913008437a61",
    CALENDAR_SWITCH = "b20_13927__975a7d332c0df9cc17e83ec00f1b6eac",
    MAX_LIMIT_SWITCH = "b20_12864__5d0abdb2cb0fa3be483de2951c7c7d1e",
    CAMPAIGN_COUNTER_EVENTS = "p20_249_fe03c9dabea83513948b3065e9aa7657",
    USE_V2_EXPORTS_SERVICE_FOR_SPIN_THE_WHEEL = "e20_120_use_v2_exports_service_for_spin_the_wheel",
    USE_V2_EXPORTS_SERVICE_FOR_SCRATCH_CARD = "e20_120_use_v2_exports_service_for_scratch_card",
    USE_V2_EXPORTS_SERVICE_FOR_PERSONALITY_TEST = "e20_120_use_v2_exports_service_for_personality",
    USE_V2_EXPORTS_SERVICE_FOR_GUESS_THE_PICTURE = "e20_120_use_v2_exports_service_for_gtp",
    USE_V2_EXPORTS_SERVICE_FOR_CALENDAR = "e20_120_use_v2_exports_service_for_calendar",
    USE_V2_EXPORTS_SERVICE_FOR_MEMORY = "e20_120_use_v2_exports_service_for_memory",
    USE_V2_EXPORTS_SERVICE_FOR_QUIZ = "e20_120_use_v2_exports_service_for_quiz",
    USE_V2_EXPORTS_SERVICE_FOR_SURVEY = "e20_120_use_v2_exports_service_for_survey",
    PENNY_MEDIA_ICONS = "b20_13362__86f4c4c19fb4ccd82d9604a60b07db5b",
    HIDE_ADS_AND_SURVEY_FOR_BILLA = "b20_8425__fde513b5c30078742d1c4c9dc48c3eba",
    RESTRICT_LOGIN_ACCESS = "p20_321__2e791dfade467dbeceac0d139084de7f"
}
